import React, {useState} from 'react';
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Typography,
} from '@mui/material';
import {MuiFileInput} from 'mui-file-input'
import PlaceHolder from 'src/assets/images/icons/placeholder.png';
import {AdminApi} from "../../../api/Api";
import {useSelector} from "../../../redux/store";

interface ICreateNewGalleryDialog {
    open: boolean;
    onClose: (value: boolean) => void;
    refetch: () => void;
}

export function CreateNewGalleryDialog({
                                           open,
                                           onClose,
                                           refetch
                                       }: ICreateNewGalleryDialog) {

    const authentication: any = useSelector((state) => state.auth)

    const [title, setTitle] = useState<string>('');
    const [subtitle, setSubtitle] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [imageDataUrl, setImageDataUrl] = useState<string | null>(null);
    const [fileWarning, setFileWarning] = useState<boolean>(false);

    const [warningFlag, setWarningFlag] = useState<boolean>(false);

    const handleCreateGallery = () => {
        if (!title) {
            setWarningFlag(true);
            return;
        }

        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        const img = new Image()
        imageDataUrl && (img.src = imageDataUrl);


        var formData = new FormData();
        formData.append("cover", selectedFile, selectedFile.name);
        formData.append("title", title);
        formData.append("subtitle", subtitle);
        formData.append("date", formattedDate);
        formData.append("width", String(img.width));
        formData.append("height", String(img.height));
        AdminApi.createNewGallery(formData, authentication.accessToken).then(() => {
            refetch();
        });
        handleClose();
    };

    const handleClose = () => {
        setWarningFlag(false);
        setTitle("");
        setSubtitle("");
        setSelectedFile(null);
        setImageDataUrl(null);
        onClose(false);
    };

    const handleFileChange = (newFile: any) => {
        setSelectedFile(newFile);
        if (newFile?.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageDataUrl(reader.result as string);
            };
            reader.readAsDataURL(newFile);
            setFileWarning(false);
        } else {
            setFileWarning(true);
            setImageDataUrl(null);
        }
    };


    return (
        <Dialog open={open} fullWidth maxWidth={'sm'} sx={{p: 5}}>
            <DialogTitle>Create New Gallery</DialogTitle>
            <DialogContent sx={{m: 1}}>
                <FormControl fullWidth>
                    <TextField
                        margin="dense"
                        id="title"
                        label="Title"
                        onChange={(event: any) => {
                            setTitle(event.target.value);
                        }}
                        type="text"
                        fullWidth
                        variant="outlined"
                        required
                        inputProps={{maxLength: 100}}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                        margin="dense"
                        id="subtitle"
                        label="Subtitle"
                        onChange={(event: any) => {
                            setSubtitle(event.target.value);
                        }}
                        type="text"
                        fullWidth
                        variant="outlined"
                        inputProps={{maxLength: 200}}
                    />
                </FormControl>
                <Card>
                    <CardMedia>
                        <img
                            src={imageDataUrl || PlaceHolder}
                            alt="preview"
                            style={{maxWidth: '60%', margin: '0 20%'}}
                        />
                    </CardMedia>
                    <CardContent>
                        <Typography>
                            Cover Image:
                        </Typography>
                        <MuiFileInput datatype={'image/*'} value={selectedFile} onChange={handleFileChange}
                                      style={{width: "80%", margin: "0 10%"}}
                                      getInputText={() => fileWarning ? 'Please Select an Image File!' : (selectedFile?.name ? selectedFile?.name : "Upload an image.")}/>
                    </CardContent>
                </Card>
                {warningFlag && (
                    <Typography variant="h6" sx={{mt: 2, color: 'red'}}>
                        *Please fill the required fields
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"secondary"} onClick={handleCreateGallery}>Create</Button>
                <Button color={"error"} onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}