import axios from 'axios';
import Links from "../constants/Links";

const baseUrl = Links.ESERUYANIK_API;

export const AuthApi = {
    login(credentials: any) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                data: credentials,
                url: baseUrl + "token/",
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}

export const AdminApi = {
    postPromise(baseUrl: string, endpoint: string, data: any) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                data: data,
                url: baseUrl + endpoint,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },

    createNewGallery(data: any, accessToken: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                data: data,
                url: baseUrl + 'gallery/',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `JWT ${accessToken}`,
                }
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },

    updateGallery(data: any, accessToken: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'PUT',
                data: data,
                url: baseUrl + 'gallery/',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `JWT ${accessToken}`,
                }
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },

    deleteGallery(data: any, accessToken: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'DELETE',
                data: data,
                url: baseUrl + 'gallery/',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `JWT ${accessToken}`,
                }
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },

    uploadPhotograph(data: any, accessToken: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                data: data,
                url: baseUrl + 'photograph/',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `JWT ${accessToken}`,
                }
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },


    deletePhotograph(data: any, accessToken: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'DELETE',
                data: data,
                url: baseUrl + 'photograph/',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `JWT ${accessToken}`,
                }
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
}
export const Api = {
    getGalleriesList() {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: baseUrl + "gallery/list",
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
    getGalleryContent(galleryId: any) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: baseUrl + `gallery/${galleryId}/content`,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
}