import React, {useEffect, useState} from "react";
import PageContainer from "../../components/PageContainer";
import {Api} from "../../api/Api";
import Links from "../../constants/Links";
import Masonry from "../../components/Masonry/Masonry";
import shuffle from "lodash.shuffle";
import {SingleGalleryDialog} from "./dialogs/SingleGalleryDialog";

export default function Home() {
    const [openGallery, setOpenGallery] = useState(false);
    const [galleryId, setGalleryId] = useState(-1);
    const [galleriesData, setGalleriesData] = useState<any[]>();
    useEffect(() => {
        Api.getGalleriesList().then((data: any) => {
            var res = data.map((gallery: any) => {
                const width = gallery.width;
                const height = gallery.height;
                return {
                    id: gallery.id,
                    css: `${Links.ESERUYANIK_API}gallery/${gallery.id}/cover`,
                    width: width,
                    height: height,
                };
            });
            setGalleriesData(shuffle(res));
        }).catch(e => console.warn(e))
    }, []);
    return (
        <PageContainer title="EserUyanik Photography">
            {!!galleriesData ?
                <>
                    {galleryId > -1 &&
                        <SingleGalleryDialog
                            open={openGallery}
                            setOpen={setOpenGallery}
                            galleryId={galleryId}
                            setGalleryId={setGalleryId}
                        />}
                    <Masonry
                        data={galleriesData}
                        setGalleryId={setGalleryId}
                        setGalleryOpen={setOpenGallery}
                    />
                </> : <></>}
        </PageContainer>
    )
}