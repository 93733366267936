export const login = (accessToken: string) => {
    return {
        type: 'LOGIN' as const,
        payload: accessToken
    };
};

export const logout = () => {
    return {
        type: 'LOGOUT' as const
    };
};

export type AuthAction = ReturnType<typeof login | typeof logout>;