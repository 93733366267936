import {ThemeOptions} from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#ffffff',
            light: '#424242',
            dark: '#757575',
        },
        secondary: {
            main: '#006064',
        },
    },
    typography: {
        h1: {
            fontWeight: 600,
            lineHeight: 1,
            letterSpacing: '0em',
        },
    },
};