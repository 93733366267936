import React, {useState} from 'react';
import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grow,
    TextField,
    Typography,
} from '@mui/material';
import {MuiFileInput} from 'mui-file-input';
import {AdminApi, Api} from "../../../api/Api";
import {useSelector} from "../../../redux/store";
import Links from "../../../constants/Links";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Masonry} from "@mui/lab";
import {useQuery} from "@tanstack/react-query";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone, {IDropzoneProps} from 'react-dropzone-uploader'
import {PhotographDetailsDialog} from "./PhotographDetailsDialog";

interface IGalleryDeatailsDialog {
    open: boolean;
    onClose: (value: boolean) => void;
    refetchGalleryList: () => void
    gallery: any;
}

export function GalleryDetailsDialog({
                                         open,
                                         onClose,
                                         refetchGalleryList,
                                         gallery
                                     }: IGalleryDeatailsDialog) {

    const baseUrl = Links.ESERUYANIK_API;
    const authentication: any = useSelector((state) => state.auth)
    const placeHolder = baseUrl + `gallery/${gallery?.id}/cover`;
    const [flag, setFlag] = useState(true);
    const [title, setTitle] = useState<string>('');
    const [subtitle, setSubtitle] = useState<string>('');
    const [selectedCover, setSelectedCover] = useState<any>(null);
    const [selectedPhotograph, setSelectedPhotograph] = useState<any>(null);
    const [coverDataUrl, setCoverDataUrl] = useState<string | null>(null);
    const [fileWarning, setFileWarning] = useState<boolean>(false);
    const [warningFlag, setWarningFlag] = useState<boolean>(false);
    const [openPhotographDetails, setOpenPhotographDetails] = useState<boolean>(false);


    const {
        data: photographsData,
        refetch
    }: any = useQuery({
        queryKey: ['photographs', gallery?.id],
        enabled: !!gallery,
        queryFn: () => {
            return Api.getGalleryContent(gallery.id)
        },
        select: (data: any) => {
            return data
        }
    })

    const handleUpdateGallery = () => {


        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        const formData = new FormData();
        if (!!selectedCover) {
            formData.append("cover", selectedCover, selectedCover.name);
            const img = new Image()
            coverDataUrl && (img.src = coverDataUrl);

            formData.append("width", String(img.width));
            formData.append("height", String(img.height));
        }
        title && formData.append("title", title);
        subtitle && formData.append("subtitle", subtitle);
        formData.append("date", formattedDate);
        formData.append("id", gallery.id)
        AdminApi.updateGallery(formData, authentication.accessToken).then(() => {
            refetchGalleryList();
        });
        handleClose();
    };
    const handleDeleteGallery = () => {
        const formData = new FormData();
        formData.append("id", gallery.id)
        AdminApi.deleteGallery(formData, authentication.accessToken).then(() => {
            refetchGalleryList();
        });
        handleClose();
    };

    const handleClose = () => {
        setWarningFlag(false);
        setTitle("");
        setSubtitle("");
        setSelectedCover(null);
        setCoverDataUrl(null);
        onClose(false);
    };

    const handleCoverChange = (newFile: any) => {
        setSelectedCover(newFile);
        if (newFile?.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCoverDataUrl(reader.result as string);
            };
            reader.readAsDataURL(newFile);
            setFileWarning(false);
        } else {
            setFileWarning(true);
            setCoverDataUrl(null);
        }
    };


    const getUploadParams: IDropzoneProps['getUploadParams'] = (file: any) => {
        const formData = new FormData();
        formData.append('gallery', gallery.id);
        formData.append('photograph', file.file);
        formData.append('width', file.meta.width);
        formData.append('height', file.meta.height);

        const headers = {
            'Authorization': `JWT ${authentication.accessToken}`,
        }
        return {url: baseUrl + 'photograph/', body: formData, headers: headers};

    }
    const onFileChange = ({meta, file}: any, status: any) => {
        setFlag(!flag);
        status === "done" && refetch();
    }
    const handleSubmit: IDropzoneProps['onSubmit'] = (allFiles) => {
        allFiles.forEach(f => f.remove())
    }


    return (gallery &&
        <>
            <PhotographDetailsDialog open={openPhotographDetails} onClose={setOpenPhotographDetails}
                                     refetchPhotographs={refetch} photograph={selectedPhotograph}/>
            <Dialog open={open} fullWidth maxWidth={'md'} sx={{p: 5}}>

                <DialogTitle>Details</DialogTitle>
                <DialogContent sx={{m: 1}}>
                    <Grid container xs={12}>
                        <Grid item xs={8}>
                            <Card sx={{m: 1, marginLeft: 0}}>
                                <CardContent>
                                    <FormControl fullWidth>
                                        <TextField
                                            value={gallery?.title}
                                            margin="dense"
                                            id="title"
                                            label="Title"
                                            onChange={(event: any) => {
                                                setTitle(event.target.value);
                                            }}
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            required
                                            inputProps={{maxLength: 100}}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <TextField
                                            value={gallery?.subtitle}
                                            margin="dense"
                                            id="subtitle"
                                            label="Subtitle"
                                            onChange={(event: any) => {
                                                setSubtitle(event.target.value);
                                            }}
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            inputProps={{maxLength: 200}}
                                        />
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4} sx={{textAlign: "center", justifyContent: "center"}}>
                            <Card sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 1,
                                marginRight: 0,
                                height: "90%",
                                textAlign: "center",
                                justifyContent: "center"
                            }}>
                                <CardContent>
                                    <Typography>
                                        Views:
                                    </Typography>
                                    <Typography>
                                        {gallery.views}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Card>
                        <CardMedia>
                            <img
                                src={coverDataUrl || placeHolder}
                                alt="preview"
                                style={{maxWidth: '40%', margin: '0 30%'}}
                            />
                        </CardMedia>
                        <CardContent>
                            <Typography style={{width: "60%", margin: "0 20%"}}>
                                Cover Image:
                            </Typography>
                            <MuiFileInput datatype={'image/*'} value={selectedCover} onChange={handleCoverChange}
                                          style={{width: "60%", margin: "0 20%"}}
                                          getInputText={() => fileWarning ? 'Please Select an Image File!' : (selectedCover?.name ? selectedCover?.name : "Upload an image.")}/>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <Typography style={{width: "60%", margin: "0 20%"}}>
                                Photographs:
                            </Typography>
                            <Dropzone
                                onSubmit={handleSubmit}
                                onChangeStatus={onFileChange}
                                getUploadParams={getUploadParams}
                                accept="image/*"
                                inputContent="Choose or Drop Photographs to Upload"
                                styles={{dropzone: {minHeight: 200, maxHeight: 250}}}

                            />
                        </CardContent>
                    </Card>

                    <Card sx={{alignItems: "center"}}>
                        {photographsData &&
                            <Masonry columns={{xs: 1, md: 2}} style={{width: "100%"}}>
                                {photographsData.map((item: any) => (
                                    <Grow key={item.id} in={true}>
                                        <Card>
                                            <CardActionArea onClick={() => {
                                                setSelectedPhotograph(item);
                                                setOpenPhotographDetails(true);
                                            }}>
                                                <img
                                                    width={"100%"}
                                                    src={baseUrl + `photograph/${item.id}`}
                                                    alt={item.title}
                                                    loading="lazy"
                                                />
                                                <Typography variant={"h5"}>
                                                    {item.title}
                                                </Typography>
                                            </CardActionArea>
                                        </Card>
                                    </Grow>
                                ))}
                            </Masonry>
                        }
                    </Card>

                    {warningFlag && (
                        <Typography variant="h6" sx={{mt: 2, color: 'red'}}>
                            *Please fill the required fields
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleDeleteGallery}>Remove</Button>
                    <Box flexGrow={1}/>
                    <Button variant="contained" onClick={handleUpdateGallery}>Update</Button>
                    <Button variant="contained" onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}