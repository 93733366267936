 import React from "react";
import SignIn from "../views/Login/Login";
import Home from "../views/Home/Home";
import BaseLayout from "../layouts/BaseLayout";
import SingleGallery from "../views/SingleGallery/SingleGallery";

const UnauthorizedRoutes = [
    {
        path: '/',
        children: [
            {
                path: '',
                element: <BaseLayout title={"Portfolio"}><Home/></BaseLayout>
            },
            {
                path: 'gallery/:id',
                element: <BaseLayout title={"Portfolio"}><SingleGallery/></BaseLayout>
            }
        ]
    },
    {
        path: '/admin',
        element: <SignIn/>
    }
];
export default UnauthorizedRoutes;