import React, {useState} from 'react';
import {Button, Dialog, DialogContent, DialogTitle,} from '@mui/material';
import {Api} from "../../../api/Api";
import Links from "../../../constants/Links";
import 'react-dropzone-uploader/dist/styles.css'
import {useQuery} from "@tanstack/react-query";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import {Masonry} from "@mui/lab";

interface ISingleGalleryDialogProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    galleryId: any;
    setGalleryId: any;
}

export function SingleGalleryDialog({
                                        open,
                                        setOpen,
                                        galleryId,
                                        setGalleryId,
                                    }: ISingleGalleryDialogProps) {

    const [index, setIndex] = useState<number>(-1);

    const {
        data: photographsData,
    }: any = useQuery({
        queryKey: ['photographs'],
        queryFn: () => {
            return Api.getGalleryContent(galleryId);
        },
        select: (data: any) => {
            return data.map((photo: any) => {
                const width = photo.width;
                const height = photo.height;
                return {
                    id: photo.id,
                    src: `${Links.ESERUYANIK_API}/photograph/${photo.id}`,
                    width,
                    height,
                };
            });
        },

    })

    const handleClose = () => {
        setGalleryId(-1);
        setOpen(false);
    };


    return (photographsData &&
        <Dialog open={open} fullWidth maxWidth={'xl'}>

            <DialogTitle>
                Album
            </DialogTitle>
            <Button sx={{
                position: "absolute",
                top: "1rem",
                right: "1rem"
            }} variant="contained" onClick={handleClose}>
                Close
            </Button>
            <DialogContent
                sx={{p: 5, textAlign: "center", justifyItems: "center", height: "80vh", padding: 0, margin: 0}}>
                <Lightbox
                    fullscreen={{auto: true}}
                    open={index >= 0}
                    close={() => {
                        setIndex(-1);
                    }}
                    slides={photographsData}
                    index={index}
                    thumbnails={{
                        showToggle: true,
                        vignette: true,
                        imageFit: "cover",
                    }}
                    carousel={{
                        padding: 0,
                        spacing: 0,
                    }}
                    controller={{
                        closeOnPullDown: true,
                        closeOnBackdropClick: true
                    }}
                    on={{
                        view: ({index}) => {
                            console.log(photographsData ? photographsData[index] : index)
                        }
                    }}
                    plugins={[Captions, Fullscreen, Thumbnails, Zoom, Counter]}
                    styles={{
                        root: {
                            "--yarl__color_backdrop": "#fff",
                            "--yarl__color_button": "#444",
                            "--yarl__color_button_active": "#000",
                            "--yarl__color_button_disabled": "#999",
                            "--yarl__button_filter": "drop-shadow(1px 1px 1px #fff)",
                            "--yarl__counter_filter": "drop-shadow(1px 1px 1px #fff)",
                        },
                        thumbnail: {background: "none"},
                    }}
                />
                <Masonry sx={{
                    overflowX: "hidden"
                }}
                         columns={{xs: 1}}
                         spacing={0}>
                    {photographsData.map((item: any, index: number) => (


                        <div key={item.id}
                             onClick={() => {
                                 setIndex(index);
                             }}
                             style={{
                                 padding: 0,
                                 margin: 0,
                                 transition: "transform 0.2s ease, width 0.2s ease, height 0.2s ease",
                             }}>
                            <img
                                src={item.src}
                                alt='eseruyanik'
                                loading="lazy"
                                style={{

                                    maxHeight: "80vh",
                                }}
                            />
                        </div>
                    ))}
                </Masonry>
            </DialogContent>
        </Dialog>
    );
}