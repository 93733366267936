import React from 'react';
import './App.css';
import {useRoutes} from "react-router-dom";
import UnauthorizedRoutes from "./routes/UnauthorizedRoutes";
import {useSelector} from "./redux/store";
import AuthorizedRoutes from "./routes/AuthorizedRoutes";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {themeOptions} from "./theme/themeOptions";

function App() {
    const authentication: any = useSelector((state) => state.auth)
    const routing = useRoutes(authentication.accessToken ? AuthorizedRoutes : UnauthorizedRoutes);
    const theme = createTheme(themeOptions)


    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                {routing}
            </div>
        </ThemeProvider>
    );
}

export default App;
