import {AppBar, Toolbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";

interface HeaderProps {
    title: string,
}

function Header({title}: HeaderProps) {
    const navigate = useNavigate();

    return (
        <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
            <Toolbar>
                <Box onClick={() => navigate("/")} sx={{cursor: "pointer"}}>
                    <Typography variant="h6" noWrap component="div">
                        ESERUYANIK.COM - {title}
                    </Typography>
                </Box>
                <Box flexGrow={1}/>
            </Toolbar>
        </AppBar>);
}

export default Header;