import {FC, ReactNode} from 'react';
import {Outlet} from 'react-router-dom';
import Header from "./Header/Header";
import Box from "@mui/material/Box";
import {Toolbar} from "@mui/material";

interface SidebarLayoutProps {
    children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
    const drawerWidth = 0;
    return (
        <Box sx={{display: 'flex'}}>
            {/*<SideBar drawerWidth={drawerWidth}/>*/}
            <Header/>
            <Box component="main" sx={{m: 0, p: 0, width: `calc(100% - ${drawerWidth}px)`}}>
                <Toolbar/>
                <Outlet/>
            </Box>
        </Box>
    );
};

export default SidebarLayout;
