import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle,} from '@mui/material';
import {AdminApi} from "../../../api/Api";
import {useSelector} from "../../../redux/store";
import Links from "../../../constants/Links";
import Box from "@mui/material/Box";
import 'react-dropzone-uploader/dist/styles.css'

interface IPhotographDeatailsDialog {
    open: boolean;
    onClose: (value: boolean) => void;
    refetchPhotographs: () => void
    photograph: any;
}

export function PhotographDetailsDialog({
                                            open,
                                            onClose,
                                            refetchPhotographs,
                                            photograph
                                        }: IPhotographDeatailsDialog) {

    const baseUrl = Links.ESERUYANIK_API;
    const authentication: any = useSelector((state) => state.auth)
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
    const handleDeletePhotograph = () => {
        const formData = new FormData();
        formData.append("id", photograph.id)
        AdminApi.deletePhotograph(formData, authentication.accessToken).then(() => {
            refetchPhotographs();
        });
        handleClose();
    };

    const handleClose = () => {
        onClose(false);
    };


    return (photograph &&
        <Dialog open={open} fullWidth maxWidth={'xl'} sx={{p: 0}}>

            <DialogTitle>Photograph</DialogTitle>
            <DialogContent
                sx={{m: 1, textAlign: "center", justifyItems: "center", height: "80vh", padding: 0, margin: 0}}>
                <img
                    src={baseUrl + `photograph/${photograph.id}`}
                    alt={photograph.gallery}
                    style={{maxWidth: "100%", maxHeight: "98%"}}
                    loading="lazy"
                />

            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={handleDeletePhotograph}>Remove</Button>
                <Box flexGrow={1}/>
                <Button variant="contained" onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}