import {FC, ReactNode} from 'react';
import PropTypes from 'prop-types';
import {Outlet} from 'react-router-dom';

import {Box, Toolbar} from '@mui/material';
import Header from "../BaseLayout/Header/Header";

interface BaseLayoutProps {
    children?: ReactNode;
    title: string

}

const BaseLayout: FC<BaseLayoutProps> = ({children, title}) => {
    return (
        <Box
            sx={{
                flex: 1,
                height: '100%'
            }}
        >
            <Header title={title}/>
            <Box component="main" sx={{m: 0, p: 0, width: `100%`}}>
                <Toolbar/>

                {children || <Outlet/>}
            </Box>
        </Box>
    );
};

BaseLayout.propTypes = {
    children: PropTypes.node
};

export default BaseLayout;
